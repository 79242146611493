import React, { useState } from "react";
import { backendUrl } from "../config/url";

const EmailForm = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleMessageChange = (e) => {
    setMessage(e.target.value);
  };

  const sendEmail = async () => {
    console.log(backendUrl);
    
    try {
      const response = await fetch(backendUrl + "/send-email", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, message }),
      });

      if (response.ok) {
        console.log("Email sent successfully");
      } else {
        console.error("Error sending email");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div>
      <input type="email" value={email} onChange={handleEmailChange} placeholder="Recipient Email" />
      <textarea value={message} onChange={handleMessageChange} placeholder="Your Message" />
      <button onClick={sendEmail}>Send Email</button>
    </div>
  );
};

export default EmailForm;
