import React, { useState } from "react";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";

const MembershipToggleButton = ({ setMonthlyOrYearly }) => {
  const [alignment, setAlignment] = useState("yearly");

  const handleAlignment = (_, newAlignment) => {
    if (newAlignment !== null) {
      setAlignment(newAlignment);
      setMonthlyOrYearly(newAlignment);
    }
  };

  return (
    <div style={{ textAlign: "center", color: "#fff", marginTop: 10 }}>
      <ToggleButtonGroup
        value={alignment}
        exclusive
        onChange={handleAlignment}
        aria-label="payment frequency"
        sx={{
          backgroundColor: "#BFA76F80",
          borderRadius: 30,
          paddingY: 1,
          paddingX: 1,
        }}
      >
        <ToggleButton
          value="monthly"
          aria-label="monthly"
          style={{
            color: alignment === "monthly" ? "#fff" : "#c0c0c0",
            backgroundColor: alignment === "monthly" ? "#BFA76F" : "transparent",
            borderTopLeftRadius: 50,
            borderBottomLeftRadius: 50,
            borderWidth: alignment === "monthly" ? 1 : 0,
            textTransform: "none",
            paddingLeft: 20,
            paddingRight: 20,
            paddingBottom: 1,
            paddingTop: 1,
          }}
        >
          Monthly
        </ToggleButton>
        <ToggleButton
          value="yearly"
          aria-label="yearly"
          style={{
            color: alignment === "yearly" ? "#fff" : "#c0c0c0",
            backgroundColor: alignment === "yearly" ? "#BFA76F" : "transparent",
            borderTopRightRadius: 50,
            borderBottomRightRadius: 50,
            borderWidth: alignment === "yearly" ? 1 : 0,
            textTransform: "none",
            paddingLeft: 20,
            paddingRight: 20,
            paddingBottom: 1,
            paddingTop: 1,
          }}
        >
          {" "}
          Yearly
        </ToggleButton>
      </ToggleButtonGroup>
    </div>
  );
};

export default MembershipToggleButton;
