import React, { useState } from "react";
import { Dialog, DialogContent, DialogActions, Button, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@emotion/react";

import ElectricBoltIcon from "@mui/icons-material/ElectricBolt";

import MembershipDialog from "./membershipDialog";

const CalculationLimitDialog = ({ open, onClose }) => {
  const theme = useTheme();
  const isSmScreen = useMediaQuery("(min-width:600px)");
  const [openMembershipDialog, setOpenMembershipDialog] = useState(false);

  return (
    <Dialog open={open} onClose={onClose} maxWidth={"xs"}>
      <DialogContent
        sx={{
          alignItems: "center",
          color: "black",
          background: "white",
          fontSize: isSmScreen ? 20 : 14,
          fontWeight: 700,
        }}
      >
        <Typography variant="h2" textAlign="center" color={theme.palette.text.third} mt={2}>
          {":("} <br />
          Daily limit exceeded...
        </Typography>

        <p>Upgrade to get rid of daily limits!</p>
        <ul>
          <li>
            Find the most profitable trades with unlimited calculations
            <p />
          </li>
          <li>
            Trade with more confidence when searching up to 10 tickers simulatneously
            <p />
          </li>
          <li>Save time with unlimited custom shortlists</li>
        </ul>
      </DialogContent>
      <DialogActions sx={{ paddingX: 2, paddingY: 2, background: "white" }}>
        <Button
          onClick={() => setOpenMembershipDialog(true)}
          startIcon={<ElectricBoltIcon />}
          sx={{
            textTransform: "none",
            textAlign: "center",
            margin: "auto",
            height: 50,
            width: 200,
            background: theme.palette.text.third,
            color: "white",
            borderRadius: "5px",
            fontSize: 20,
            marginBottom: 2,
            "&:hover": {
              background: theme.header.background,
              opacity: 0.9,
              border: `${theme.palette.text.third} solid 1px`,
            },
          }}
        >
          Upgrade Now
        </Button>
      </DialogActions>
      <MembershipDialog
        open={openMembershipDialog}
        onClose={() => {
          setOpenMembershipDialog(false);
        }}
      />
    </Dialog>
  );
};

export default CalculationLimitDialog;
