import React from "react";
import { Card, CardContent, Typography, Avatar, Grid } from "@mui/material";

const ExpertCard = ({ data }) => {
  const cardStyle = {
    color: "#fff",
    padding: 1,
    minHeight: 330,
    marginTop: 1,
    borderColor: "text.third",
    borderStyle: "solid",
    borderWidth: "1px",
  };
  
  return (
    <Card sx={cardStyle}>
      <CardContent>
        <Grid container spacing={2} alignItems="center">
          <Grid item>
            <Avatar alt="name" src={`/img/experts/${data.photo}.png`} />
          </Grid>
          <Grid item>
            <Typography variant="h6">{data.name}</Typography>
            <Typography variant="body2" color="textSecondary">
              {data.location}
            </Typography>
          </Grid>
        </Grid>
        <Typography variant="body1" sx={{ marginTop: 2 }}>
          {data.text}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default ExpertCard;
