import React, { useState, useRef, useEffect } from "react";
import { Dialog, DialogContent, DialogActions, Button, Typography } from "@mui/material";
import { useTheme } from "@emotion/react";

import MembershipDialog from "./membershipDialog";
import ElectricBoltIcon from "@mui/icons-material/ElectricBolt";

const UpgradeFreeAccount = ({ open, onClose }) => {
  const theme = useTheme();
  const [openMembershipDialog, setOpenMembershipDialog] = useState(false);
  const targetSectionRef = useRef(null);

  const buttonStyle = {
    textTransform: "none",
    textAlign: "center",
    margin: "auto",
    height: 50,
    width: 200,
    background: theme.palette.text.third,
    color: "white",
    borderRadius: "5px",
    fontSize: 20,
    marginBottom: 2,
    "&:hover": {
      background: theme.header.background,
      opacity: 0.9,
      border: `${theme.palette.text.third} solid 1px`,
    },
  };

  useEffect(() => {
    if (openMembershipDialog) {
      const timer = setTimeout(() => {
        if (targetSectionRef.current) {
          targetSectionRef.current.scrollIntoView({ behavior: "smooth" });
        } else {
          console.log("targetSectionRef.current is still null");
        }
      }, 100); // Adjust the delay as necessary

      return () => clearTimeout(timer); // Cleanup the timer
    }
  }, [openMembershipDialog]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs">
      <DialogContent
        sx={{
          alignItems: "center",
          color: "black",
          background: "white",
          fontSize: 20,
        }}
      >
        <Typography variant="h2" textAlign="center" color={theme.palette.text.third} mt={2}>
          Let's upgrade your account!
        </Typography>
        <p style={{ textAlign: "center" }}>
          Upgrade to a paid premium account to stay on top of your options selling game.
        </p>
        <ul>
          <li>
            All the features you love - <strong>unlimited</strong>
          </li>
          <br />
          <li>Make the most of your portfolio</li>
          <br />
          <li>Navigate the opaque options market with ease</li>
        </ul>
        <p>
          <u
            style={{ color: "black", cursor: "pointer" }}
            onClick={() => {
              setOpenMembershipDialog(true);
            }}
          >
            Secured by our double-or-nothing profit promise!
          </u>
        </p>
      </DialogContent>
      <DialogActions sx={{ paddingX: 2, paddingY: 2, background: "white" }}>
        <Button
          onClick={() => {
            setOpenMembershipDialog(true);
          }}
          startIcon={<ElectricBoltIcon />}
          sx={buttonStyle}
        >
          Upgrade Now
        </Button>
      </DialogActions>

      <MembershipDialog
        open={openMembershipDialog}
        onClose={() => setOpenMembershipDialog(false)}
        targetRef={targetSectionRef}
      />
    </Dialog>
  );
};

export default UpgradeFreeAccount;
