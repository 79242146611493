import { Accordion, AccordionSummary, AccordionDetails, Typography } from "@mui/material";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

const summaries = [
  "What benefits do I get with a premium subscription compared to the free plan?",
  "How does the 'Double-or-Nothing Guarantee' work?",
  "Can I try the premium features before committing to a paid plan?",
  "How can this app save me time compared to my current research process?",
  "Is this app suitable for both beginners and experienced options traders?",
  "What makes the options screener unique compared to other tools on the market?",
  "How will the premium subscription improve my trading performance?",
  "What happens if I need help or encounter an issue with the app?",
  "How can I track my favorite stocks and options opportunities more effectively with a premium account?",
  "Is the premium subscription worth the cost if I'm only a casual trader?",
];

const details = [
  "With a premium subscription, you unlock unlimited daily executions, the ability to compare up to 10 tickers on a single page, and access to weekly options for finding hidden ROI opportunities. You can also save and manage unlimited shortlists for efficient portfolio tracking, all with priority support to assist you whenever you need.",
  "If you don't double the cost of your COVERD subscription in trading profits within a given month, we’ll refund your subscription fee for that month—no questions asked. Simply send us an email, and we'll take care of the rest.",
  "Absolutely! We encourage users to start with our free plan to explore the basic functionalities. When you're ready to upgrade, you'll have immediate access to the premium tools that simplify your trading and help you discover better opportunities.",
  "COVERD consolidates hours of options research into just a few clicks, eliminating the need for endless spreadsheets and manual data tracking. With our advanced screener and simple charting of expiration dates, you'll identify the best trades in seconds, not hours.",
  "Yes, COVERD is designed to support all skill levels. Beginners can benefit from easy-to-understand tools that identify profitable opportunities, while experienced traders can use advanced screening features and comprehensive comparisons to fine-tune their strategies.",
  "Our options screener focuses specifically on selling opportunities like covered calls and cash-secured puts. It filters for hidden opportunities in weekly options and helps you quickly identify expiration dates that fit your strategy—all in one intuitive interface.",
  "The premium features allow you to conduct in-depth comparisons and access hidden opportunities that others might miss. By making faster, more informed decisions, you're more likely to maximize returns and secure consistent profits.",
  "Premium subscribers receive priority support, meaning your inquiries are handled with top priority. Whether you have a technical question or need guidance on using a feature, our dedicated team is here to assist you.",
  "With a premium subscription, you can save and manage unlimited shortlists, allowing you to easily track your preferred stocks and options in one place. This means you can stay on top of your portfolio and react quickly to market changes.",
  "Even casual traders will find value in COVERD's ability to streamline decision-making and surface opportunities that can lead to higher returns. Plus, with our Double-or-Nothing Guarantee, you can rest assured that the value you gain will always outweigh the cost—or you'll get your money back.",
];

const FrequentlyAskedQuestions = () => {
  return (
    <div>
      {summaries.map((summary, index) => (
        <Accordion sx={{ marginTop: "10px !important" }}>
          <AccordionSummary
            expandIcon={<ArrowDropDownIcon />}
            aria-controls={`panel${index}-content`}
            id={`panel${index}-header`}
            sx={{ color: "white" }}
          >
            {summary}
          </AccordionSummary>
          <AccordionDetails>
            <Typography>{details[index]}</Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};

export default FrequentlyAskedQuestions;
