import React, { useState } from "react";
import { Dialog, DialogContent, DialogActions, Button, Typography } from "@mui/material";

import ElectricBoltIcon from "@mui/icons-material/ElectricBolt";

import MembershipDialog from "./membershipDialog";

import { useTheme } from "@emotion/react";

const ConversionFreeAccount = ({ open, onClose }) => {
  const theme = useTheme();
  const [openMembershipDialog, setOpenMembershipDialog] = useState(false);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs">
      <DialogContent
        sx={{
          alignItems: "center",
          color: "black",
          background: "white",
          fontSize: 20,
          fontWeight: 700,
        }}
      >
        <Typography variant="h2" textAlign="center" color={theme.palette.text.third} mt={2}>
          Sign up now!
        </Typography>
        <div style={{ color: theme.palette.text.third, textAlign: "center" }}>It's free.</div>
        <ul>
          <li>
            Analyze 3 tickers side by side
            <p />
          </li>
          <li>
            Shortlist: Keep your top 3 investment ideas easily accessible
            <p />
          </li>
          <li> Up to 3 daily executions </li>
        </ul>
      </DialogContent>
      <DialogActions sx={{ paddingX: 2, paddingY: 2, background: "white" }}>
        <Button
          onClick={() =>
            setOpenMembershipDialog(true)
          }
          startIcon={<ElectricBoltIcon />}
          sx={{
            textTransform: "none",
            textAlign: "center",
            margin: "auto",
            height: 50,
            width: 200,
            background: theme.palette.text.third,
            color: "white",
            borderRadius: "5px",
            fontSize: 20,
            marginBottom: 2,
            "&:hover": {
              background: theme.header.background,
              opacity: 0.9,
              border: `${theme.palette.text.third} solid 1px`,
            },
          }}
        >
          Upgrade Now
        </Button>
      </DialogActions>
      <MembershipDialog
        open={openMembershipDialog}
        onClose={() => {
          setOpenMembershipDialog(false);
        }}
      />
    </Dialog>
  );
};

export default ConversionFreeAccount;
