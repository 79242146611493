import { Grid } from "@mui/material";
import ExpertCard from "../../../experts/expert-card";

const cardData = [
  {
    name: "Mason I.",
    location: "Dallas, TX",
    photo: "Mason",
    text: "I've been using Coverd Premium for a couple of months now, and it's streamlined my entire options trading process.",
  },
  {
    name: "Satish G.",
    location: "Mumbai, India",
    photo: "Satish",
    text: "Upgraded to the premium plan mainly for the unlimited executions, and weekly options. It's been totally worth it. I didn't realize how much I was missing out on before coverd.",
  },
  {
    name: "Florian S.",
    location: "Cyprus",
    photo: "Florian",
    text: "Honestly didn't expect much, but COVERD surprised me. It's simple but effective—makes spotting opportunities for covered calls way faster than my old spreadsheets. If you're trading options regularly, it's a solid tool to have.",
  },
  {
    name: "Jason N.",
    location: "Singapore",
    photo: "Jason",
    text: "I've been selling options to boost returns by 20-30% per year on my portfolio way before Lovis and I built this app. The difference is, now I find better trades, with less risk, without hours of research.",
  },
  {
    name: "Mudit J.",
    location: "Richardson, TX",
    photo: "Mudit",
    text: "Tried the free plan first, then upgraded, and it's been smooth. The weekly options feature alone is worth it—makes finding those sweet spots a lot easier than doing it manually.",
  },
  {
    name: "John L.",
    location: "USA - Options Trader",
    photo: "John",
    text: "Cool app! I am pretty sure no one else is doing this.",
  },
];

const ExpertsTrustUs = () => {
  return (
    <Grid container spacing={3}>
      {cardData.map((data, index) => (
        <Grid item xs={12} sm={4} key={index}>
          <ExpertCard data={data}/>
        </Grid>
      ))}
    </Grid>
  );
};

export default ExpertsTrustUs;
