import React, { useEffect, useState } from "react";
import { Dialog, DialogContent, Button, Typography, Grid, useMediaQuery, Box } from "@mui/material";

import { useTheme } from "@emotion/react";
import { useSelector, useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import MembershipToggleButton from "./sections/membership/membershipToggleButton";
import FrequentlyAskedQuestions from "./sections/membership/frequentlyAskedQuestions";
import ExpertsTrustUs from "./sections/membership/expersTrustUs";

import { createCheckoutSession } from "../../actions/stripeAction";

const MembershipDialog = ({ open, onClose, targetRef }) => {
  const theme = useTheme();
  const isSmScreen = useMediaQuery("(min-width:600px)");
  const dispatch = useDispatch();
  const fieldsetStyle = {
    marginLeft: isSmScreen ? 150 : 20,
    marginRight: isSmScreen ? 150 : 20,
    borderRadius: 5,
    borderColor: theme.palette.text.third,
  };

  const { isAuthenticated, loginWithRedirect } = useAuth0();
  const userType = useSelector((state) => state.stripe.userType);
  const [MonthlyOrYearly, setMonthlyOrYearly] = useState("yearly");
  const [premiumValue, setPremiumValue] = useState(49);
  const [eliteValue, setEliteValue] = useState(199);

  useEffect(() => {
    if (MonthlyOrYearly === "monthly") {
      setPremiumValue(49);
      setEliteValue(199);
    } else {
      setPremiumValue(41);
      setEliteValue(166);
    }
  }, [MonthlyOrYearly]);

  const onSelectedFreePlan = () => {
    sessionStorage.setItem("selectedPlan", "Free");
    loginWithRedirect({
      authorizationParams: { screen_hint: "signup" },
    });
  };

  const onSelectedProPlan = () => {
    let plan;

    if (MonthlyOrYearly === "monthly") {
      sessionStorage.setItem("selectedPlan", "Premium");
      plan = "Premium";
    } else {
      sessionStorage.setItem("selectedPlan", "Premium Yearly");
      plan = "Premium Yearly";
    }

    if (isAuthenticated) {
      dispatch(
        createCheckoutSession({
          plan: plan,
        })
      );
    } else {
      loginWithRedirect({
        authorizationParams: { screen_hint: "signup" },
      });
    }
  };

  const onSelectedElitePlan = () => {
    let plan;

    if (MonthlyOrYearly === "monthly") {
      sessionStorage.setItem("selectedPlan", "Elite");
      plan = "Elite";
    } else {
      sessionStorage.setItem("selectedPlan", "Elite Yearly");
      plan = "Elite Yearly";
    }

    if (isAuthenticated) {
      dispatch(
        createCheckoutSession({
          plan: plan,
        })
      );
    } else {
      loginWithRedirect({
        authorizationParams: { screen_hint: "signup" },
      });
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xl" fullWidth>
      <DialogContent
        sx={{
          alignItems: "center",
          color: "white",
          fontSize: 20,
        }}
      >
        <Typography variant="h1" textAlign="center" color="white" mt={1} sx={{ fontSize: 35 }}>
          Choose your plan
        </Typography>
        <Typography textAlign="center" color="white" mt={2} sx={{ fontSize: 14 }}>
          Get 2 months free when you pay yearly
        </Typography>
        <MembershipToggleButton setMonthlyOrYearly={setMonthlyOrYearly} />
        <Grid
          container
          gap={3}
          paddingX={2}
          sx={{
            marginTop: 4,
            justifyContent: "center",
            "& fieldset": { borderColor: "text.third", borderRadius: 1, fontSize: 14 },
            "& legend": { fontSize: 15 },
            "& .MuiButton-root": {
              borderColor: "text.third",
              borderWidth: 1,
              borderStyle: "solid",
              color: "text.third",
              marginY: 1,
            },
          }}
        >
          <Grid item xs={12} sm={3}>
            <fieldset>
              <legend>Free</legend>
              <Typography variant="h2" textAlign="center">
                $0/mo
              </Typography>
              <ul>
                <li>Analyze 3 tickers side by side - up to 3 daily executions </li>
                <br />
                <li>Keep your top 3 investment ideas easily accessible </li>
                <br />
                <li>Explore fundamental options with the basic screener</li>
              </ul>
              <div style={{ textAlign: "center" }}>
                <Typography sx={{ fontSize: 12 }}>7 Days of Premium access for free</Typography>
                {isAuthenticated && userType === "Free" ? (
                  <Button endIcon={<CheckCircleIcon />}>Selected</Button>
                ) : (
                  <Button onClick={() => onSelectedFreePlan()}>Select Plan</Button>
                )}
              </div>
            </fieldset>
          </Grid>
          <Grid item xs={12} sm={3}>
            <fieldset>
              <legend>Premium</legend>
              <Box display="flex" justifyContent="center" alignItems="flex-end" gap={1}>
                {MonthlyOrYearly === "yearly" && (
                  <Typography color="text.secondary">
                    <s>$49</s>
                  </Typography>
                )}
                <Typography variant="h2" textAlign="center">
                  ${premiumValue}/mo
                </Typography>
              </Box>
              {MonthlyOrYearly === "yearly" && (
                <Typography color="text.secondary" textAlign="center">
                  billed $490 yearly
                </Typography>
              )}
              <ul>
                <li>Unlimited daily executions </li>
                <br />
                <li>Compare up to 10 tickers on one page </li>
                <br />
                <li>Find hidden ROI with WEEKLY options</li> <br />
                <li>Save and manage unlimited shortlists for efficient portfolio tracking</li> <br />
                <li>Discover optimal trades with our advanced options screener</li> <br />
                <li>Find the expiration dates that best fit your strategy in one simple chart</li> <br />
                <li>Priority support: Receive dedicated assistance whenever you need it.</li>
              </ul>
              <div style={{ textAlign: "center" }}>
                <Typography sx={{ fontSize: 12 }}>14 day trial - cancel any time</Typography>
                {isAuthenticated && (userType === "Premium" || userType === "Premium Yearly") ? (
                  <Button endIcon={<CheckCircleIcon />}>Selected</Button>
                ) : (
                  <Button
                    onClick={onSelectedProPlan}
                    sx={{ color: "white !important", background: "#BFA76F !important" }}
                  >
                    Select Plan
                  </Button>
                )}
              </div>
            </fieldset>
          </Grid>
          <Grid item xs={12} sm={3}>
            <fieldset>
              <legend>Elite</legend>
              <Box display="flex" justifyContent="center" alignItems="flex-end" gap={1}>
                {MonthlyOrYearly === "yearly" && (
                  <Typography color="text.secondary">
                    <s>$199</s>
                  </Typography>
                )}
                <Typography variant="h2" textAlign="center">
                  ${eliteValue}/mo
                </Typography>
              </Box>
              {MonthlyOrYearly === "yearly" && (
                <Typography color="text.secondary" textAlign="center">
                  billed $1990 yearly
                </Typography>
              )}
              <ul>
                <li>Direct communication with Jason & Lovis: Gain insights and guidance from our founders </li>
                <br />
                <li>Access to weekly options trades from our founders incl. portfolio updates </li>
                <br />
                <li>Early access to new features</li>
              </ul>
              <div style={{ textAlign: "center" }}>
                <Typography sx={{ fontSize: 12 }}>14 day trial - cancel any time</Typography>
                {isAuthenticated && (userType === "Elite" || userType === "Elite Yearly") ? (
                  <Button endIcon={<CheckCircleIcon />}>Selected</Button>
                ) : (
                  <Button onClick={onSelectedElitePlan}>Select Plan</Button>
                )}
              </div>
            </fieldset>
          </Grid>
        </Grid>

        <Typography textAlign="center" my={3}>
          Turn hours of options research and endless spreadsheets into insights gained in seconds.
        </Typography>
        <Typography textAlign="center" sx={{ color: "text.third", paddingX: isSmScreen ? 20 : 0 }} ref={targetRef}>
          <u>Double-or-Nothing Profit Promise:</u>
          <br />
          If you don't double what COVERD costs you in trading profits in a given month, you'll get that month's
          subscription fee back. <br />
          No questions asked - send an email - get your refund
        </Typography>

        <Typography variant="h4" textAlign="center" mb={2} mt={4}>
          Frequently Asked Questions:
        </Typography>
        <fieldset style={fieldsetStyle}>
          <FrequentlyAskedQuestions />
        </fieldset>

        <Typography variant="h4" textAlign="center" mb={2} mt={4}>
          Experts Trust Us
        </Typography>
        <fieldset style={fieldsetStyle}>
          <ExpertsTrustUs />
        </fieldset>
      </DialogContent>
    </Dialog>
  );
};

export default MembershipDialog;
